// function MyImageButton(link,image, title,color) {
//     return <a href={link}>
//         <div className="image-container">
//             <img class="stud img-fluid w-75 " src={image} />
//             {/* <button className="btn" style={{backgroundColor:color}}>

//                 {title}
//             </button> */}
//         </div>

//     </a>;
// }

import React, { Component } from 'react'

class MyImageButton extends Component{
    state = {  } 
    render() { 

        return <a href={this.props.link}>
        <div className="image-container">
            <img class="stud img-fluid w-75 " src={this.props.image} />
            <button className="btn" style={{backgroundColor:this.props.color}}>

                {this.props.title}
            </button>
        </div>

    </a>;
    }
}

export default MyImageButton;