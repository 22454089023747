import { Component } from "react";

class Loading extends Component{
    render(){
        return <>
        <div className="loadingcenterCenter">
        <h1>Loading ...</h1>
        </div>
        </>
    }
}

export default Loading;
