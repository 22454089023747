import { Component } from "react";
import { usePaystackPayment } from 'react-paystack';

class LoadWallet extends Component{
    state = {};
     config = {
        reference:this.props.reference??(new Date()).getTime().toString(),
        email: this.props.email??'info@teleprintersoftwares.com',
        amount: 10000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: 'pk_live_fa303447b00743c7868bae9aa90f7325278a0467', //'pk_test_080180c6af7b49eb73515a5320935cc8fffd213e' //
    };
    
    // you can call this function anything
     onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
      this.props.onSuccess();
      console.log(reference);
    };
  
    // you can call this function anything
     onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      this.props.onClose();
      console.log('closed')
    }
    componentProps = {
        ...this.config,
        text: this.props.title,
        onSuccess: (reference) => this.onSuccess(reference),
        onClose: this.onClose,
    };
    render(){
            return <>
            <PaystackHookExample 
            {...this.componentProps}
            // config={this.config} onSuccess={this.onSuccess} onClose={this.onClose} 
            />
            </>
    }
}

const PaystackHookExample = (props) => {
    var config = {
        reference: props.reference??(new Date()).getTime().toString(),
        email: props.email??'hallykola@gmail.com',
        amount: 10000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: 'pk_live_fa303447b00743c7868bae9aa90f7325278a0467', //'pk_test_080180c6af7b49eb73515a5320935cc8fffd213e', //
    };
    var onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        props.onSuccess();
        console.log(reference);
      };
    
      // you can call this function anything
      var onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        props.onClose();
        console.log('closed')
      }
    const initializePayment = usePaystackPayment(config);
    return (
      <div>
          <button class="btn btn-info btn-lg text-white" onClick={() => {
              initializePayment(onSuccess, onClose)
          }}>{props.text}</button>
      </div>
    );
};

export default LoadWallet