import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import GroupTile from './grouptile';

class CreateCourseBody extends Component {
   mymap = new Map();
    state = {  myform:{} , s:[], groupingmap:{}, } ;
    

    handleSubmit = (e)=>{
      e.preventDefault();
      var atoken = localStorage.getItem('token');
      const baseURL = "https://courses."+process.env.REACT_APP_BACKEND+"/course" ; //process.env.REACT_APP_COURSE_BACKEND ;

      var formattedgroups={};
        for(var i = 0; i < this.state.s.length; i++) {
        var  value = this.state.s[i];
        console.log('value na '+value.label);
        var label = value.label;
        var all = value;
        delete all.label;
        if(formattedgroups.hasOwnProperty(label)){
          toast("Two groups can not have the same name.");
          return false;
        }
        formattedgroups[label]=all;
      }
      console.log(formattedgroups);
      const {course,info, more} = this.state.myform;
      const { groupingmap} = this.state
      var data = {course:course, info:info, more:more, groupingmap:formattedgroups, token:localStorage.getItem('token')};
      console.log(baseURL,data);
      axios.post(baseURL,data,{headers: {
        'token': `${atoken}`
      }}
        ).then((response) => {
        console.log(response);
        
       
        toast("Course created");
        formattedgroups = {};
        // toast(response.data.token);
        // localStorage.setItem('token',response.data.token);
        window.location.href = '/listcourses'

      }).catch((e)=>{
        console.log(e.response);
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast(e.response.data[a.toString()][0]);
            // console.log(e.response.data[a.toString()][0]);

            console.log(e.response.data);

           }
        }
        
         
      });

      // console.log(name,password);
    }

    handleinput= (e)=>{
      const myform = {...this.state.myform};
      myform[e.target.name] = e.target.value;
      this.setState({myform});
      console.log(this.state.myform);
    }

    addGroup=(e)=>{
      var ans  = this.state.s;
      
      ans.push({"label":'',"department":'',"numberpergroup":''});
     
      this.setState({s:ans});
      console.log(this.state.s.length,this.state);
      
    }

    deleteGroup=(i,val)=>{
      console.log("delete "+ val);
      var ans  = this.state.s;
       ans.splice(i,1);
      this.setState({s:ans});
    
      var gmap = this.state.groupingmap
      delete gmap[val]
      
      this.setState({groupingmap:gmap},()=>{console.log(this.state)});
  
      
    }

    sendvalues=(val,i)=>{
      var gmap = this.state.groupingmap
      var s = this.state.s
      console.log("see me",val);
      s[i]= {"label":val.label,"department":val.department,"numberpergroup":val.numberpergroup};
      gmap[val.label]= {"department":val.department,"numberpergroup":val.numberpergroup};
      this.setState({groupingmap:gmap},()=>{console.log(this.state)});

    }

    showGroup= ()=>{
      this.state.s.map((object, i)=>{
      // return <GroupTile obj={object} adelete={()=>this.deleteGroup(i)} key={i} />;
      return <GroupTile obj={object} num={i} adelete={(val,i)=>this.deleteGroup(val,i)} key={i} />;

  }
  
  )
  
    }
    
    render() { 
       
        return (
            <React.Fragment>
             <div>
       
        <ToastContainer />
      </div>
      <section class="showcase mt-5">
    <section class="p-3">
        <p class="per text-secondary fs-3 mx-4 mb-5">Welcome, <strong>{this.props.user.profile.name}</strong></p>
        <div class="container shadow-lg p-3 rounded bg-white px-3">
            <p class="pere text-secondary fs-3 text-warning mb-5">Create a Course</p>
            <form onSubmit={this.handleSubmit} class="row g-3 text-secondary">
                <div class="col-md-6">
                  <label for="inputname" class="form-label">Course Code</label>
                  <input type="coursename" class="form-control" id="inputname" onChange={this.handleinput} name="course" placeholder='e.g ABC 123' title='e.g ABC 123' required/>
                </div>
                <div class="col-md-6">
                  <label for="inputmatricno" class="form-label">Course Title</label>
                  <input type="coursetitle" class="form-control" id="inputmatricno" onChange={this.handleinput} name="info" placeholder='e.g Introduction to Laboratory practice' title='e.g Introduction to Laboratory practice' required/>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Course Description</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='e.g This course is for 100 level students ...' title='e.g This course is for 100 level students ...'  onChange={this.handleinput} name="more"></textarea>
                  </div>
                 

                 
               { this.state.s.map((element, i)=>{
                // element.name
      return <GroupTile sendvalues={this.sendvalues} obj={element} num={i} adelete={(val)=>this.deleteGroup(i,val)} key={i} randomval={Math.floor(Math.random() * 10) + 1}/>})
      }
      <br/>

<button type="button" onClick={this.addGroup} style={{backgroundColor:"green"}}> Add Group</button>
  
                  
                  
                
                {/* <div class="col-md-12">
                  <label for="inputname" class="form-label">Admin</label>

                  <input type="coursename" class="form-control" id="inputname" onChange={this.handleinput} name="department"/>
                  <input type="coursename" class="form-control" id="inputname" onChange={this.handleinput} name="department"/>
                  <input type="coursename" class="form-control" id="inputname" onChange={this.handleinput} name="department"/>
                </div> */}
                
                <div class="col-12">
                <button type="submit" class="btn btn-info btn-lg text-white">Create Course</button>
                </div>
              </form>
            </div>
    </section>
        </section>
            </React.Fragment>
        );
    }
}
 
export default CreateCourseBody;