import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import GroupRow from './grouprow';

import MyDialog from './mydialog';



import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { getData } from '../services/myauth';

class  ViewCourseAttendanceBody extends Component {
    state = {  data:[],rows:[],open:false } ;
     thecourse = decodeURI(window.location.href.split('/')[4]);
     group = decodeURI(window.location.href.split('/')[5]);
    

    getStudents=()=>{
      console.log('hello');
      

      var atoken = localStorage.getItem('token');
      const user = getData();
      const baseURL = `https://registerstudent.`+ process.env.REACT_APP_BACKEND+`/course/getcoursestudentforgroup?course=${this.thecourse}&group=${this.group}`;
      // `https://registerstudent.`+process.env.REACT_APP_BACKEND+`/course/getmycourses?userid=${user.profile.id}`  ; //process.env.REACT_APP_COURSE_BACKEND ;

      axios.get(baseURL,{headers: {
        'token': `${atoken}`
      }}
        ).then((response) => {
        console.log(response.data);
        var regs = response.data.registrations.sort((elementa,elementb)=>elementa.number-elementb.number);
        this.setState({data:regs},()=>{console.log('checky data',this.state.data)});
        toast('Done');
     
      }).catch((e)=>{
        console.log(e.response);
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast(e.response.data[a.toString()][0]);
            console.log(e.response.data[a.toString()][0]);
           }
        }
        
         
      });
    }
    
    removeStudent=(userid)=>{
      

      var atoken = localStorage.getItem('token');
      const user = getData();
      const baseURL = `https://registerstudent.`+ process.env.REACT_APP_BACKEND+`/course/removestudent`;
      var data = {course:this.thecourse, group:this.group, userid:userid};

      axios.post(baseURL,data,{headers: {
        'token': `${atoken}`
      }}
        ).then((response) => {
        console.log(response.data);
        var regs = response.data.registrations.sort((elementa,elementb)=>elementa.number-elementb.number);

        this.setState({data:regs},()=>{console.log('checky data',this.state.data)});
        toast('Done');
     
      }).catch((e)=>{
        console.log(e.response);
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast(e.response.data[a.toString()][0]);
            console.log(e.response.data[a.toString()][0]);
           }
        }
        
         
      });
    }
   
    componentDidMount(){
      this.getStudents();
     } 
    render() { 
      
    
        return (
            <section >
    <section class="p-3">
    <div>
      <ToastContainer />
    </div>
      <div style={{
        // width:"3000px",
        padding:"50px"
        }} class=" shadow-lg ">
            <p class="pere text-secondary fs-3 text-warning mb-5 fw-bold">Attendance of Students in {this.group} </p>
            {/* <p class="pere text-secondary fs-3 text-warning mb-5 fw-bold">{`${this.thecourse} ${this.group}${this.groupno}`}</p> */}
            <hr/>
            {/* <h1>{this.state.data.courses[0].course}</h1> */}
            <hr/>
            <br/>
            <form class="row g-3 text-secondary">
            <div className="view">
                <div className="wrapper">
              <table class='table' style={{
                // width:"3000px"
                border:"2px solid black"
                
            }}>
              <thead>
              <tr>
              <th> S/N </th>   
          <th class=" sticky-col first-col"> Group number </th>   
          <th > Names</th>  
          <th> Matric  Number </th>  
          <th> Department </th>  
          <th > A </th>  
          <th> B </th> 
          <th> C </th>  
          <th> D </th>
          <th> E </th>  
          <th> F </th>
          <th> G </th>  
          <th> H </th>
          <th> I </th>  
          <th> J </th>

          </tr>
          </thead>
          
             {this.state.data.map((studentRegDetail,index) => (  
          // <a href={`/viewgroup/${acourse.course}/${acourse.group}/${acourse.number}`}>  
          <tr>
            <td> {index+1} </td> 
          <td class=" sticky-col first-col" > {studentRegDetail.group} {studentRegDetail.number} </td>   
          <td > {studentRegDetail.userprofile.name} </td>  
          <td> {studentRegDetail.userprofile.matricnumber} </td>  
           <td> {studentRegDetail.userprofile.department} </td>  
          {/*<td> {studentRegDetail.userprofile.email} </td>   */}
          {/* <td> {studentRegDetail.userprofile.phonenumber} </td>  */}
          {/* <td>   */}
            
            {/* <MyDialog title={`Delete ${studentRegDetail.userprofile.name}'s registration?`} body={ `This is an irreversible process. Student might not get the same group number if you register them again.`}
            actionFunction={()=>this.removeStudent(studentRegDetail.student)} actionTitle={`Remove Student`} buttonTitle={'Remove Student'}/>
             */}
      
             {/* </td>  */}
         {[0,1,2,3,4,5,6,7,8,9].map((val)=>{
          return <td style={{
            border:"2px solid black"
            
        }}></td>;
         })}
        
          </tr>
          // </a>  
        ))}  
        
              </table>

              </div> </div>
            <br/>
            <br/>
           
            {/* {this.state.data.map(studentRegDetail => (  
          // <a href={`/viewgroup/${acourse.course}/${acourse.group}/${acourse.number}`}>  
          <p> {studentRegDetail.userprofile.name} </p>  
          // </a>  
        ))}   */}
                  
              </form>
              </div>
              </section>
              </section>
        );
    }
}
 
export default ViewCourseAttendanceBody;