import { Component } from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';



class WhatIsPalMeeUp extends Component{
    state = {open:false};
    setOpen = (value)=>{
        this.setState({open:value});
    }
    title = this.props.title??'How to use PalMeeUp'; 
         body = this.props.body??'Here is an explanation of how to use Pal Mee Up. ';
         actionFunction = ()=>{
            // window.location.href = "/palmeeup";
         }; 
         actionTitle = this.props.actionTitle??'I understand'; 
         buttonTitle = this.props.buttonTitle??'How to use PalMeeUp';
    showInfo = ()=>{
        this.setOpen(true);
    }
    goToPalmeeup=()=>{
      // window.location.href = "/palmeeup";
    }
    render(){
        return <>
         <Button variant="outlined" onClick={() =>this.setOpen(true)}>
        {this.buttonTitle}
      </Button>
        <Dialog
        open={this.state.open}
        onClose={()=>this.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                {this.body}
                {/* <iframe width="560" 
                height="315" 
                src="https://www.youtube.com/embed/i3A1009zkNc?si=Kh44RgxEmw9W-zm0" 
                title="YouTube video player" 
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
         */}
         <iframe width="100%"  src="https://www.youtube-nocookie.com/embed/i3A1009zkNc?si=Rkc7h9IQBnmGOaUG" 
         title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowfullscreen></iframe>
          </DialogContentText> 
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setOpen(false)}>Cancel</Button>
          <Button onClick={()=>{this.actionFunction()
          this.setOpen(false);
          }} autoFocus>
            {this.actionTitle}
          </Button>
        </DialogActions>
      </Dialog> 
      
        </>
    }
}

export default WhatIsPalMeeUp;