import { Component } from "react";

class VoucherGenerator extends Component{
    state = {show:'visible'};


    handleSingleInput= (e)=>{
        const others = {...this.state.others};
        others[e.target.name] = e.target.value;
        
        this.setState({others},()=>{console.log('bobo',this.state)});
        
      }

    generate = ()=>{
        const others = {...this.state.others};
        var vouchers = others.vouchers.split(' ');
        others['vouchersList'] = vouchers
        var show = this.state.show;
        show = 'collapse';
        this.setState({others, show},()=>{console.log('bobo',this.state)});
        console.log(others.vouchersList);
    } 
    render(){
        return <>
        <div style={{
            // visibility:this.state.show
        }}>
             <input type="text" name="title" onChange={this.handleSingleInput} required/>
             <br />

        <input type="text" name="vouchers" onChange={this.handleSingleInput} required/>
        <br />
       
        <button onClick={this.generate} > Generate</button>
        </div>
        <div>
        <div className="row">
        {this.state.others?.vouchersList!=null? this.state.others.vouchersList.map((e,i)=>
       
          
           <>
                <div className="col-4">
                    <div className='card' style={{textAlign:"center",padding:'2px'}}>
                        {/* <small> {i+1} </small> */}
                    <hr />
                        {`${i+1}. ${this.state.others.title}${''
                            // e
                            // .substring(0, 6)
                            } `}
                        <hr />
                        <h6>Steps:</h6>
                        <p>1. Visit "varsityreghub.com" <br />
                        2. Click on Course registration from the menu at the top.<br />
                        3. Follow all instructions and supply the licence number below when needed.</p>
                    <h5>{e}</h5>
                    </div>
                  { (i%9 === 0) && <div class="pagebreak"></div>}
            
            </div>
           
            </>
        
    ):[]}
     </div>
    </div>
        </>
    }
}

export default VoucherGenerator;