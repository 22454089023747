import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import {deptOptions} from '../services/departments';
import Select from 'react-select';

import axios from "axios";
import 'react-toastify/dist/ReactToastify.css'; 
import MypasswordInput from './mypasswordinput';
import LoadWallet from './loadwallet';



class RegisteraStudentBody extends Component {
  state = {stage:1,charge:this.props.charge ,myform:{
      // 'email':this.props.user.email
  }, course:this.props.course,loading:false, reference:"empty" } ;
    atoken = localStorage.getItem('token');
    
   UNSAFE_componentWillMount(){
    this.checkAndUpdateCharge();
    this.createAndSetReferenceNumber();
   } 
   

   createAndSetReferenceNumber =()=>{
    var ref = (new Date()).getTime().toString();
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 4; i++) {
    const randomChar = chars.charAt(Math.floor(Math.random() * chars.length));
    ref = ref +'A'+ randomChar;
  }
    this.setState({reference:ref},()=>{
      //  toast(`Set payment reference ${this.state.reference}`);
    })
  }
    handleSubmit1 = (e)=>{
      e.preventDefault(); 
      // this.checkAndUpdateCharge();
     
      const baseURL = "https://auth."+process.env.REACT_APP_BACKEND+"/auth/getstudentprofile" ;
      
      var data = {...this.state.myform,};
      data.email=data.email.trim();

      // console.log("see me",baseURL,data);
      axios.post(baseURL,data,{headers: {
        'token': `${this.atoken}`
      }}
        ).then((response) => {
        // console.log(response);
          this.handlecheckuserresponse(response);
       
        
        
        // window.location.href = '/stafflogin'
    
      }).catch((e)=>{
        // console.log(e.response);

        
        
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast('A technical error occured');
            // toast(e.response.data[a.toString()][0]);
            // console.log(e.response.data[a.toString()][0]);
           }
        }
     


    })
  }
  handlecheckuserresponse = (response)=>{
     // check if student exists in system
    
     if(response.data['error']){
      toast(response.data['error']);
      //if user no exist
      this.setState({stage:2},()=>{
        // console.log('stage 2');
      })
    }else{
        //if user exists
        this.setState({stage:3,profile:response.data.profile},()=>{
          // console.log('stage 3',this.state.profile.id);
          toast("User exists");
        })

    }
   
  }
  handleSubmit2 = (e)=>{
    e.preventDefault();
    
    const baseURL = "https://auth.varsityreghub.com/auth/registerstudentforprofile" ;
    var data = {...this.state.myform,};
    // console.log(data);
    if(data.password!=data.passwordagain){
      toast('Passwords don\'t match');
      return;
    }
    // console.log("see me",baseURL,data);
    axios.post(baseURL,data,{headers: {
      'token': `${this.atoken}`
    }}
      ).then((response) => {
      // console.log(response);
        toast(response.data.profile)
      
        this.setState({stage:3,profile:response.data.profile},()=>{
          // console.log('stage 3',this.state);
        })
      // window.location.href = '/stafflogin'
  
    }).catch((e)=>{
      // console.log(e.response);

      
      
      // window.location.href = '/'
      if(e.response.data['error']){
        toast(e.response.data['error']);
      }else{
        for (let a in e.response.data){
          toast('A technical error occured');
          // toast(e.response.data[a.toString()][0]);
          // console.log(e.response.data[a.toString()][0]);
         }
      }
   


  })
    
  }

  checkAndUpdateCharge= ()=>{
 //check if course is allowed to charge
 const baseURL = `https://courses.varsityreghub.com/course?course=${this.state.course}` ;
 
 axios.get(baseURL,{headers: {
   'token': `${this.atoken}`
 }} ).then((response) => {
  //  console.log('take', response.data);
   var chargeValue = response.data.course.premium.toLowerCase() == 'true';
   chargeValue = chargeValue&&this.props.charge;
   this.setState({charge:chargeValue},()=>{
    // console.log('see charge updated',this.state);
  })
   
  });
}

  // canDeptTakeCourse = ()=>{
    
  // }
  
    handleSubmit3 = async(e)=>{
      e.preventDefault();
      
      //check if student is allowed to take course
      const baseURL = "https://courses.varsityreghub.com/course/checkallowed" ;
      var data = {...this.state.profile ,course:this.state.course,feature:this.state.profile.department};
      var allowed = false;
      console.log("see me",baseURL,data);
      axios.post(baseURL,data,{headers: {
        'token': `${this.atoken}`
      }} ).then((response) => {
        console.log('take', response.data);
        allowed = response.data.status ;
        var depts = response.data.depts;
        // depts.sort();
        if(!allowed){
          toast(`Student\'s department is not allowed. Department can only be one of the following: ${depts.join(', ')}.`)
          return;
        }
         
        
        if(this.state.charge){
          this.setState({stage:9},()=>{
            // console.log('stage 4');
          });
        }else{
        this.setState({stage:4},()=>{
          // console.log('stage 4');
        })};
      
    
      }).catch((e)=>{
        console.log(e.response);
        
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast(e.response.data[a.toString()][0]);
            console.log(e.response.data[a.toString()][0]);
           }
           return false;
        }
     


    })
      

     
      
    }

    handleSubmita = (e)=>{
      e.preventDefault();
      this.setState({stage:4},()=>{
        // console.log('stage 4');
      })
      return;
      var loading = true;
      this.setState({loading},()=>{
        // console.log(this.state);
      });
       const baseURL = "https://registerstudent.varsityreghub.com/course" ;
      var data = {...this.state.profile ,course:this.state.course,userid:this.state.profile.id, license:this.state.myform.license};

      // console.log("see me",baseURL,data);
      axios.post(baseURL,data,{headers: {
        'token': `${this.atoken}`
      }} ).then((response) => {
        this.setState({loading:false});
        // console.log(response);
          toast(`${this.state.profile.name} is now registered`)
        
          this.setState({stage:5,grouping:response.data.grouping},()=>{
            // console.log('stage 5');
          })
           }).catch((e)=>{
            this.setState({loading:false});
        // console.log(e.response);

        
        
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
          
        }else{
          for (let a in e.response.data){
            toast('A technical error occured')
            // toast(e.response.data[a.toString()][0]);
            // console.log(e.response.data[a.toString()][0]);
           }
        }
     


    })
   
    }

    handleSubmit4 = (e)=>{
      e.preventDefault();
      var loading = true;
      this.setState({loading},()=>{
        // console.log(this.state);
      });
       const baseURL = "https://registerstudent.varsityreghub.com/course" ;
      var data = {...this.state.profile ,course:this.state.course,userid:this.state.profile.id, license:this.state.myform.license, reference:this.state.reference};

      // console.log("see me",baseURL,data);
      axios.post(baseURL,data,{headers: {
        'token': `${this.atoken}`
      }} ).then((response) => {
        this.setState({loading:false});
        // console.log(response);
          toast(`${this.state.profile.name} is now registered`)
        
          this.setState({stage:5,grouping:response.data.grouping},()=>{
            // console.log('stage 5');
          })
           }).catch((e)=>{
            this.setState({loading:false});
        // console.log(e.response);

        
        
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
          
        }else{
          for (let a in e.response.data){
            toast('A technical error occured')
            // toast(e.response.data[a.toString()][0]);
            // console.log(e.response.data[a.toString()][0]);
           }
        }
     


    })
   
    }

    handleinput= (e)=>{
        const myform = {...this.state.myform};
        myform[e.target.name] = e.target.value;
        
        this.setState({myform},()=>{
          // console.log(this.state.myform)
        });
        
      }

      handleDeptChange = (selectedOption) => {
        const myform = {...this.state.myform};
        selectedOption = selectedOption.label;
        // selectedOption.map((value)=>value.label)
        myform['department'] = selectedOption;
        this.setState({myform}, () =>{
          // console.log(`New form details:`, this.state.myform)
        }
        );
      };
    
    showPasswordDetails(){
      return (<>
      <form onSubmit = {this.handleSubmit2} class="row g-3 text-secondary">
        <p>Student does not exist on database. Enter details to create account for</p>
        <p className="pere text-secondary fs-5 text-warning mb-2 fw-bold">{ this.state.myform.email}</p>
        <div class="col-md-6">
                  <label for="inputname" class="form-label">Name</label>
                  <input type="name" class="form-control" id="inputname" onChange={this.handleinput} name="name" value={this.state.myform.name??''}/>
                </div>
                <div class="col-md-6">
                  <label for="inputmatricno" class="form-label">Matriculation number</label>
                  <input type="text" class="form-control" id="inputmatricno" onChange={this.handleinput} name="matricnumber" value={this.state.myform.matricnumber??''}/>
                </div>
                 
                <div class="col-md-4">
                  <label for="inputlevel" class="form-label">Department</label>
                  
                  <Select
        isMulti={false}
        isSearchable={true}
        value={{'label':this.state.myform.department,'value':this.state.myform.department}}
        onChange={this.handleDeptChange}
        options={deptOptions}
      />
                </div>

                <div class="col-md-4">
                  <label for="inputlevel" class="form-label">Level</label>
                  <select id="inputlevel" class="form-select" onChange={this.handleinput} name="level" value={this.state.myform.level??'Choose level'}>
                    <option>100 Level</option>
                    <option>200 Level</option>
                    <option>300 Level</option>
                    <option>400 Level</option>
                    <option>500 Level</option>
                    <option>Diploma</option>
                    <option>MSc</option>
                    <option> Others</option>
                    <option selected> Choose level</option>
                  </select>
                </div>
       <div class="col-md-4">
                  <label for="inputphonenumber" class="form-label">Phone number</label>
                  <input type="text" class="form-control" id="Phonenumber" onChange={this.handleinput} name="phonenumber" value={this.state.myform.phonenumber??''}/>
                </div>
                <div class="col-md-4">
                    <label for="inputPassword" class="form-label">Password</label>
                    <MypasswordInput className="form-control mb-3" onChange={this.handleinput} name='password' id="inputpassword" />

                    {/* <input type="password" class="form-control" id="inputPassword" onChange={this.handleinput} name="password" value={this.state.myform.password??''}/> */}
                  </div>
                  <div class="col-md-4">
                    <label for="inputPassword4" class="form-label"> Confirm Password</label>
                    <MypasswordInput className="form-control mb-3" onChange={this.handleinput} name='passwordagain' id="inputpassword" />

                    {/* <input type="password" class="form-control" id="inputPassword4" onChange={this.handleinput} name="passwordagain" value={this.state.myform.passwordagain??''}/> */}
                  </div>
                <div class="col-12">
                  <div class="form-check">
                    {/* <input class="form-check-input" type="checkbox" id="gridCheck"/> */}
                    {/* <label class="form-check-label" for="gridCheck" onChange={this.handleinput} name="name" value={this.state.myform.name??''}>
                      I agree that the information provided above is true.
                    </label> */}
                  </div>
                </div>
                <div class="col-6">
                <button type="button" onClick={()=>{this.setState({stage:1})}} class="btn btn-info btn-lg text-white">Previous </button>
                </div>
                <div class="col-6">
                <button type="submit"  class="btn btn-info btn-lg text-white">Next </button>
                </div>
                
                </form>
      </>);
    }
    showRegisterDetails=()=>{
      return(
<>
<form onSubmit = {this.handleSubmit1} class="row g-3 text-secondary">
               
                <div class="col-md-12">
                  <label for="inputemail" class="form-label">Student's Email Address</label>
                  <input type="text" class="form-control" id="inputemail" onChange={this.handleinput} name="email" 
                  value={this.state.myform.email??''}
                   placeholder='type in student email here' required/>
                </div>
               
              
               
                <div class="col-12">
                <button  class="btn btn-info btn-lg text-white">Register Student</button>
                </div>
              </form></>
      );
    }

    showWalletPayment = ()=>{
     
      
      return (<>
      {/* <form onSubmit = {this.handleSubmita} class="row g-3 text-secondary"> */}
      <p>This process attracts a token of 100 naira as system charges. Do not interrupt midway to prevent starting over again. </p>
      {/* <p  style={{color:'blue'}} onClick={()=>{this.setState({stage:4})}}> */}
<p>
<a href="https://forms.gle/VJE72fyLLbS7wdoF7" > 
Already paid but was interrupted? Were you charged but could not register? Click here
 </a> 
         </p>

      <div className="row">
      <div class="col-6">
        
                <button type="button" onClick={()=>{this.setState({stage:1})}} class="btn btn-info btn-lg text-white">Previous </button>
                </div>
                <div class="col-6">
                {/* this.props.user.email??this.state.profile.email */}
                <LoadWallet email={this.state.profile.email} reference={this.state.reference} title={'Proceed, I understand and agree.'} onSuccess={()=>{this.setState({stage:4})}} onClose={()=>()=>{this.setState({stage:1})}}/>

      {/* <button type="submit" class="btn btn-info btn-lg text-white">Proceed</button> */}
      </div>
      </div>
       {/* </form> */}
      </>);
    }
    showStudentDetails= ()=>{
      return(<>
      <form onSubmit = {this.handleSubmit3} class="row g-3 text-secondary">
        <p class="pere text-secondary fs-5 text-warning mb-2 fw-bold">Confirm details of student to be registered for {this.state.course}.</p>
        <p class="pere text-secondary fs-5 text-primary mb-2 fw-bold">Name: {this.state.profile.name}</p>
        <p class="pere text-secondary fs-5 text-primary mb-2 fw-bold"> Matric number: {this.state.profile.matricnumber} </p>
        <p class="pere text-secondary fs-5 text-primary mb-2 fw-bold"> Department: {this.state.profile.department} <br />  </p>
        <p class="pere text-secondary fs-5 text-primary mb-2 fw-bold"> Phone number: {this.state.profile.phonenumber} </p>
        <p class="pere text-secondary fs-5 text-primary mb-2 fw-bold"> Email: {this.state.profile.email} </p>

        <p></p>
        <div class="col-5">
                <button type="button" onClick={()=>{this.setState({stage:1})}} class="btn btn-info btn-lg text-white">Previous </button>
                </div>
                <div className="col-2"></div>
        <div class="col-5">
        <button type="submit" class="btn btn-info btn-lg text-white">Proceed</button>
        </div>
        </form>
      </>);
    }
    showGetLicenseForm=()=>{
      return(
<>
<form onSubmit = {this.handleSubmit4} class="row g-3 text-secondary">
                
                <div class="col-md-12">
                  <label for="inputemail" class="form-label">Enter License number</label>
                  <input type="text" class="form-control" id="license" onChange={this.handleinput} name="license" value={this.state.myform.license??''} required />
                </div>
                <div class="col-5">
                {(!this.state.charge) && <button type="button" onClick={()=>{this.setState({stage:1})}} class="btn btn-info btn-lg text-white">Previous </button>}
                </div>
              
                <div className="col-2"></div>
                <div class="col-5">
                {(!this.state.loading)&&<button  class="btn btn-info btn-lg text-white">Proceed</button>}
                {(this.state.loading)&& <h5>Loading ...</h5>}
                </div>
              </form></>
      );
    }
    showCompletedRegisterationDetails= ()=>{
      return(<>
        <p class="pere text-secondary fs-5 text-success mb-2 fw-bold">SUCCESS</p>
        <h1 class="pere text-secondary fs-5 text-success mb-2 fw-bold">Group: {this.state.grouping.group} {this.state.grouping.number}</h1>
        <p>{this.state.profile.name} is now registered to: {this.state.grouping.group} {this.state.grouping.number} for {this.state.grouping.course}</p>
        <div class="col-6">
                <button type="button" onClick={()=>{this.setState({stage:4})}} class="btn btn-info btn-lg text-white">Previous </button>
                </div>
        <div class="col-6">
        {/* <a href='/studentregister'>
          <button type="button"  class="btn btn-info btn-lg text-white">Register A New Student</button>
          </a> */}
                    <button type="button" onClick={()=>{this.setState({stage:1})}} class="btn btn-info btn-lg text-white">Register A New Student</button>

        </div>
      </>);
    }
    render() { 
      // console.log(this.props.user);
        return (
            <section class="showcase mt-5" >
    <section class="p-3" style={{"min-height":"100%"}}>
    <div>
       <ToastContainer />
     </div>
      <div class="container shadow-lg p-1 rounded bg-white px-3" >
            <p class="pere text-secondary fs-3 text-success mb-2 fw-bold">{this.state.course}</p>
            <p class="pere text-secondary fs-3 text-warning mb-5 fw-bold">Student Course Registration</p>
            {this.state.stage==1&&this.showRegisterDetails()}
            {this.state.stage==2&&this.showPasswordDetails()}
            {this.state.stage==3&&this.showStudentDetails()}
            {this.state.stage==9&&this.showWalletPayment()}
            {this.state.stage==4&&this.showGetLicenseForm()}
            {this.state.stage==5&&this.showCompletedRegisterationDetails()}
              </div>
            </section>
            </section>
        );
    }
}
 
export default RegisteraStudentBody;