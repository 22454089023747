import React, { Component } from 'react';
import ResetPasswordBody from './mycomponents/resetpasswordbody';
import Navbar from './mycomponents/navbar';

class ResetPassword extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <Navbar/>
                <ResetPasswordBody/>
            </React.Fragment>
        );
    }
}
 
export default ResetPassword;