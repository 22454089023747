import { Component } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


class MyDialog extends Component{
    state = {open:false};
    setOpen = (value)=>{
        this.setState({open:value});
    }
     title = this.props.title; 
         body = this.props.body;
         actionFunction = this.props.actionFunction; 
         actionTitle = this.props.actionTitle; 
         buttonTitle = this.props.buttonTitle;

    render(){
        
        return <> 
        <Button variant="outlined" onClick={() =>this.setOpen(true)}>
        {this.buttonTitle}
      </Button>
        <Dialog
        open={this.state.open}
        onClose={()=>this.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                {this.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setOpen(false)}>Cancel</Button>
          <Button onClick={()=>{this.actionFunction()
          this.setOpen(false);
          }} autoFocus>
            {this.actionTitle}
          </Button>
        </DialogActions>
      </Dialog> 
      </>;
    }
}

export default MyDialog;