import React, { Component } from 'react'

class AdsRect extends Component{
    state = {  } 
    render() { 
        return ;
        // (
        //     <div style={{width:'300px',height:'250px',backgroundColor:this.props.color}} className='tileContainer'  >
        //      {/* <div style={{width:'336px',height:'280px',backgroundColor:this.props.color}} className='tileContainer'  > */}
        //      <div id="adc2f23"></div>
        //      <iframe className='fluid' src="https://cybertronads.com/platform/show.php?z=27&pl=2406" width="300" height="250" marginwidth="0" marginheight="0" hspace="0" vspace="0" frameborder="0" scrolling="no"></iframe>
        //     </div>
        // );
    }
}
 
export default AdsRect;