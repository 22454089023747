import React, { Component } from 'react';
import ForgotPasswordBody from './mycomponents/forgotpasswordbody';
import Navbar from './mycomponents/navbar';

class ForgotPassword extends Component {
    state = {  } 
    render() { 
        return (
            <React.Fragment>
                <Navbar/>
                <ForgotPasswordBody/>
            </React.Fragment>
        );
    }
}
 
export default ForgotPassword;