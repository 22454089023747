import { Component } from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';



class PalmeeupIcon extends Component{
    state = {open:false};
    setOpen = (value)=>{
        this.setState({open:value});
    }
    title = this.props.title??''; 
         body = this.props.body??'Pal Mee Up is medium for connecting with people with shared interest. ';
         actionFunction = ()=>{
            window.location.href = "/palmeeup";
         }; 
         actionTitle = this.props.actionTitle??'Go to PalMeeUp'; 
         buttonTitle = this.props.buttonTitle??'OK';
    showInfo = ()=>{
        this.setOpen(true);
    }
    goToPalmeeup=()=>{
      window.location.href = "/palmeeup";
    }
    render(){
        return <>
        <Dialog
        open={this.state.open}
        onClose={()=>this.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {this.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                {this.body}
                {/* <iframe width="560" 
                height="315" 
                src="https://www.youtube.com/embed/i3A1009zkNc?si=Kh44RgxEmw9W-zm0" 
                title="YouTube video player" 
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
         */}
         <iframe width="100%"  src="https://www.youtube-nocookie.com/embed/i3A1009zkNc?si=Rkc7h9IQBnmGOaUG" 
         title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowfullscreen></iframe>
          </DialogContentText> 
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setOpen(false)}>Cancel</Button>
          <Button onClick={()=>{this.actionFunction()
          this.setOpen(false);
          }} autoFocus>
            {this.actionTitle}
          </Button>
        </DialogActions>
      </Dialog> 
        <div class="floating-container">
  <div class="floating-button">+</div>
  <div class="element-container">
  
 
    <span onClick={this.goToPalmeeup}  class="float-element tooltip-left">
      <i class="material-icons"> groups
      </i>
    </span>

 
      <span class="float-element" onClick={this.showInfo} >
      <i class="material-icons" >info
</i>
    </span>
    
      <span onClick={this.goToPalmeeup} class="float-element">
      <i class="material-icons">chat</i>
    </span>
   
  </div>
</div>
        </>
    }
}

export default PalmeeupIcon;