import React, {Component } from 'react';
import Navbar from './mycomponents/navbar';
import RegisteraStudentBody from './mycomponents/registerastudentbody';
import { withRouter } from "react-router";

class RegisteraStudent extends Component {
    state = {  } ;
   
   
    render() { 
        const course = decodeURI(window.location.href.split('/')[4]);
        const user= this.props.user;
        return (
            <React.Fragment>
            <Navbar/>
            <RegisteraStudentBody course={course}  user={user} charge={this.props.charge}/>
            </React.Fragment>
        );
    }
}
 
export default RegisteraStudent;