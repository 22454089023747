import { Component } from 'react';
import {getTagUsers,addTag,removeTag,fetchProfile,tagMatch} from "./helpers";
import Loading  from "./loading";
import BackWithHeader from './components/backheader';
import Navbar  from "../mycomponents/navbar"


class MyChats extends Component{
    state = {email:this.props.email,profile:{}};

    // email = window.location.pathname.split('/')[2]??''; //this.props.email; //
    saveProfileToState = (profile)=>{
        this.setState({profile})
    }
    componentDidMount(){
        // console.log(this.email);
        if(this.email!==''){
            fetchProfile(this.state.email,this.saveProfileToState);
        }
    }

    render(){
        return (this.state.profile.full_name==null)?<><Loading/></>:<>
            <Navbar/>
            <div  className='center'>
            <div className="centerTop">
        <BackWithHeader title="My Chats"/>
        </div>
        {/* <h1>My Chats</h1> */}
        {/* <p>{(this.state.profile.email!==null)&&this.state.profile.picture }</p> */}
        {/* {<img className='profileImage' src={this.state.profile.picture??'/images/profilepix.png'} alt='profile' height='300vh' />} */}

        {/* {(this.state.profile.picture !== null) && <img className='profileImage' src={this.state.profile.picture} alt='profile' height='300vh' />} */}
        <br/>
        {/* {this.state.profile.tags?this.state.profile.tags.map((tag)=>{
            return <span className="activeTags">{tag} 
            </span>;
        }):<p>No tags to show </p> 
        } */}
        {/* <p>Full name: {(this.state.profile.email!==null)&&this.state.profile.full_name }</p>
        <p>Bio: {(this.state.profile.email!==null)&&this.state.profile.bio }</p>
        <p>Email: {(this.state.profile.email!==null)&&this.state.profile.email }</p>
        <p>Phone: {(this.state.profile.phone_visibility)&&this.state.profile.phone }</p>
        <p>Twitter: {(this.state.profile.email!==null)&&this.state.profile.twitter }</p>
        <p>Instagram: {(this.state.profile.email!==null)&&this.state.profile.instagram }</p>
        <p>LinkedIn: {(this.state.profile.email!==null)&&this.state.profile.linkedin }</p>
        <p>Facebook: {(this.state.profile.email!==null)&&this.state.profile.facebook }</p>
        <p>Date Joined: {(this.state.profile.email!==null)&&this.state.profile.date_joined }</p>
        <a href={'/chat/'+ this.state.profile.email} >Chat with {this.state.profile.full_name}</a> */}
        <div className='chatList'>
        {
            this.state.profile.friends?this.state.profile.friends.map((friendemail)=>{
                // return <span onClick={()=>{
                //     this.addTagValue(value);}} className="trendingTags" style={{backgroundColor:this.getColor()}}>
                //     {value} <span onClick={()=>{
                //         this.addTagValue(value);
                //         // this.getColor();
                //     }}>+ </span></span>
                return     <>  <a href={`/chat/${friendemail}` } className='chatListtext'>{friendemail}</a>
                <br/>
                            </>
            }):<p>No chats yet</p>
        }
</div>
        </div>
        
        </>
    }
}

export default MyChats;