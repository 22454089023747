import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import GroupRow from './grouprow';
import AdsRect from './ads_rect_large';
import  BannerImage from './banner_image';



import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { getData } from '../services/myauth';

class ViewMyGroupListBody extends Component {
    state = {  data:[],rows:[] } ;
   

    getGroups=()=>{
      console.log('hello');
      

      var atoken = localStorage.getItem('token');
      const user = getData();
      const baseURL = `https://registerstudent.`+process.env.REACT_APP_BACKEND+`/course/getmycourses?userid=${user.profile.id}`  ; //process.env.REACT_APP_COURSE_BACKEND ;

      axios.get(baseURL,{headers: {
        'token': `${atoken}`
      }}
        ).then((response) => {
        // console.log(response.data,user.profile);
        this.setState({data:response.data.courses},()=>{console.log('checky data',this.state.data)});
        toast('Done');
     
      }).catch((e)=>{
        // console.log(e.response);
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast(e.response.data[a.toString()][0]);
            console.log(e.response.data[a.toString()][0]);
           }
        }
        
         
      });
    }
    
    
    componentDidMount(){
      this.getGroups();
     } 
    render() { 
      
    
        return (
            <section >
    <section class="p-2">
    <div>
      <ToastContainer />
    </div>
      <div style={{
        // width:"3000px",
        // padding:"15px"
        margin: '10px 0px 0px 0px'
        }} class=" shadow-lg ">

       

            <p class="pere text-secondary fs-3 text-warning mb-5 fw-bold">My Registered Labs  </p>
            {/* <p class="pere text-secondary fs-3 text-warning mb-5 fw-bold">{`${this.thecourse} ${this.group}${this.groupno}`}</p> */}
            <hr/>
            {/* <h1>{this.state.data.courses[0].course}</h1> */}
            <BannerImage/>
            <hr/>
            <br/>
            <div className="row">
              <div className="col-3"><AdsRect color='red'></AdsRect></div>
              <div className="col-1"></div>
              <div className="col-3 moreadimage"><AdsRect color='blue'></AdsRect></div>
              <div className="col-1"></div>
              <div className="col-3 onemoreadimage"><AdsRect color='green'></AdsRect></div>
            </div>
            <form class="row g-3 text-secondary" style={{
        padding:"15px"
        }}>
              <table style={{
                // width:"3000px"
            }}>
             
              </table>
            <br/>
            <br/>
            {
            this.state.data.length<1?<h3 style={{color:"green",fontSize:"40px", textAlign:'center'}}>Loading ...</h3>:
           this.state.data.map(acourse => (  
          <a href={`/viewgroup/${acourse.course}/${acourse.group}/${acourse.number}`}>  
           <div class="card m-2 p-2"> {acourse.course.toString()}  </div>
          </a>  
        ))}  
                  
              </form>
              </div>
              </section>
              </section>
        );
    }
}
 
export default ViewMyGroupListBody;