import React from 'react';
import axios from "axios";


class ImagePopup extends React.Component {

    state = {
        imageSrc: null,
        progress: 0,
        ads:[]
    };

     
    getAds= ()=>{
        //get course details
        const baseURL = `https://ads.varsityreghub.com/getads/flyer` ;
        
        axios.get(baseURL,{headers: {
        //   'token': `${this.atoken}`
        }} ).then((response) => {
        var ads = response.data.ads;
          this.setState({ads:ads},()=>{
           console.log('see ads updated',this.state);
           if(this.state.ads.length>0){
           this.fetchImage("https://ads.varsityreghub.com/adimage/"+this.state.ads[0].image).then(()=>{
                 // Hide the popup after 5 seconds
        this.timeout = setTimeout(() => {
            const popup = document.getElementById('imagePopup');
            if (popup) {
                popup.style.display = 'none';
            }
        }, 5000);
        this.progressInterval = setInterval(() => {
            this.setState(prevState => ({
                progress: prevState.progress + 1
            }));
        }, 50);
         });
        }
         }
         );
           });
       
       }

       fetchImage = async (url) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Failed to fetch image');
          }
          const blob = await response.blob();
          this.setState({ imageSrc: URL.createObjectURL(blob) });
        } catch (error) {
          console.error(error);
        }
      };

    componentDidMount() {
        this.getAds();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        clearInterval(this.progressInterval);
        if (this.state.imageSrc) {
            URL.revokeObjectURL(this.state.imageSrc);
          }
    }

    render() {
        const { progress } = this.state;
        return (
            //"https://ads.varsityreghub.com/image/"+this.state.ads[0].image
            (this.state.imageSrc!=null)? <div id="imagePopup" style={{ display: 'block', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999, backgroundColor: 'white', padding: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
               <a href={this.state.ads[0].link}> 
               {/* <img src={this.state.imageSrc} alt="advert" height="500vw"    /> */}
               {this.state.ads[0].image.endsWith('.mp4') ? (
                    <video autoPlay muted loop style={{  maxHeight:"80vh",maxWidth:"80vw" }}>
                        <source src={this.state.imageSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img src={this.state.imageSrc} alt="advert" style={{ maxHeight:"80vh",maxWidth:"80vw"}} />
                )} 
                </a>
                
               <div style={{ marginTop: '10px', height: '5px', backgroundColor: 'lightgray', position: 'relative', width: '100%' }}>
                    <div style={{ height: '100%', width: `${progress}%`, backgroundColor: 'green' }}></div>
                </div>

                <span style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', backgroundColor: 'red', borderRadius: '50%', padding: '10px'  }} onClick={() => document.getElementById('imagePopup').style.display = 'none'}> <i style={{ color: 'white' }}  class="fa fa-close"></i></span>
            </div>:<div></div>
        );
    }
}

export default ImagePopup;
