import jwt_decode from "jwt-decode";
// import { jwtDecode } from "jwt-decode";


const getUrl = ()=>{
  var url = (decodeURI (window.location.href)).split('/').slice(2);
  var path = url
    return {
     url: url,
     isInternalPage: !(url[1]=='' ||  url[1].includes('login'))
    }
}

const isTokenExpired = (time)=>{
        //currentTime is more than token expiry time - token is expired
  var currentTimeStamp = Math.floor(Date.now() / 1000);
      //  console.log(currentTimeStamp > time,currentTimeStamp - time,'current:', currentTimeStamp, 'exp:',time);
    return (currentTimeStamp > time) 
}

const  redirectTo = (location)=>{
 window.location.href = location;
}

const getData = ()=>{
    const rawtoken = localStorage.getItem('token');
    var token = jwt_decode(rawtoken);
    // console.log(token);
    const data = token.sub;
    if (isTokenExpired(token.exp)) {
      var url = getUrl();
      localStorage.removeItem('token');
        localStorage.removeItem('qwrgggsdfg');
      if(url.isInternalPage){
         redirectTo('/');
      }
      return null;
    }  
    return data; 
  }
  
  
export{
    getData
}

//return to sign in page
      // window.location.href = '/';