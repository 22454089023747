import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import StaffLogin from './stafflogin';
import StudentLogin from './studentlogin';
import StudentRegister from './studentregister';
import StaffRegister from './staffregister';

import StudentDash from './studentdash';
import StaffDash from './staffdash';
import { getData } from './services/myauth';

import ViewEditCourse from './vieweditcourse';
import CreateCourse from './createcourse';
import ListCourses from './listcourses';
import RegisteraStudent from './registerastudent';
import ManageGroups from './managegroups';
import ListCoursesforReg from './listcoursesforreg';
import StudentProfile from './studentprofile';
import StaffProfile from './staffprofile';
import ListGroups from './listgroups';
import ViewMyGroup from './viewmygroup';
import ViewMyGroupList from './viewmygrouplist';
import ForgotPassword from './forgotpassword';
import ResetPassword from './resetpassword';
import ViewCourseStudents from './viewcoursestudents';
import VoucherGenerator from './mycomponents/voucher';

import Auth from './palmeeup/auth';
import Dashboard from './palmeeup/dashboard';
import Profile from './palmeeup/profile';
import EditProfile from './palmeeup/editprofile';
import Chat from './palmeeup/chats';
import MyChats from './palmeeup/mychats';


import { app } from "./palmeeup/firebase";
import ViewCourseAttendance from './viewcourseattendance';



const root = ReactDOM.createRoot(document.getElementById('root'));
const user = localStorage.getItem('token')!=null ? getData():null;
// console.log('see me here',user);
// const user = {};

root.render(
  // <React.StrictMode>
    
    <BrowserRouter >
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route index element={<Home/>} />
      <Route path="/stafflogin" element={<StaffLogin user = {user} />} />
      <Route path="/studentlogin" element={<StudentLogin user = {user}/>} />

      <Route path="/staffprofile" element={<StaffProfile  user = {user} />} />
      <Route path="/studentprofile" element={<StudentProfile  user = {user} />} />

      <Route path="/studentregister" element={<StudentRegister/>} />
      <Route path="/staffregister" element={<StaffRegister/>} />
      <Route path="/studentdashboard" element={<StudentDash user = {user}/>} />
      <Route path="/staffdashboard" element={<StaffDash user = {user}/> } />
      <Route path="/courses" element={<ViewEditCourse  user = {user} />} />
      <Route path="/createcourse" element={<CreateCourse user = {user}/>} />
      <Route path="/listCourses" element={<ListCourses user = {user}/>} />
      
      <Route path="/listgroups" element={<ListGroups user = {user}/>} />
      <Route path="/guestregisterstudent" element={<ListCoursesforReg user = {{'name':'guest'}}/>} />
      <Route path="/registerstudent" element={<ListCoursesforReg user = {user}/>} />
      <Route path="/registerstudent/:course" element={<RegisteraStudent user = {user??{'email':null}} charge={true}/>} />
      <Route path="/registerstudent/:course/abcd" element={<RegisteraStudent user = {user??{'email':null}} charge={false}/>} />

      <Route path="/managegroups/:course/:group" element={<ManageGroups/>} />
      <Route path="/viewgroup/:course/:group/:groupno" element={<ViewMyGroup/>} />
      <Route path="/viewgroup" element={<ViewMyGroupList/>} />
     
      <Route path="/viewstudents/:course/:group" element={<ViewCourseStudents/>} />

      <Route path="/viewattendance/:course/:group" element={<ViewCourseAttendance/>} />


      <Route path="/forgotpassword" element={<ForgotPassword/>} />
      <Route path="/resetpassword" element={<ResetPassword/>} />
      <Route path="/generatevoucher" element={<VoucherGenerator/>} />

      <Route path="/palmeeup" element={(user!==null)?<Dashboard email={user.email}/>:<StudentLogin/>} />
      <Route path="/profile/:email" element={(user!==null)? <Profile email={user?.email}/>:<StudentLogin/>} />
      <Route path="/editprofile" element={(user!==null)?<EditProfile email={user?.email}/>:<StudentLogin/>} />
      <Route path="/chat/:email" element={(user!==null)?<Chat email={user?.email} />:<StudentLogin/>} />
      <Route path="/mychats" element={(user!==null)?<MyChats email={user?.email} />:<StudentLogin/>} />

    </Routes>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
