import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import GroupRow from './grouprow';


import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { cleanRow } from '../services/cleanrow';

class ManageGroupsBody extends Component {
    state = {  myform:[],rows:[],others:{valuetoassign: '', selectedgroups: '', selectedcolumn: 'A'} } ;
    
    getGroups=()=>{
      console.log('hello');
      const thecourse = decodeURI(window.location.href.split('/')[4]);
      const group = decodeURI(window.location.href.split('/')[5]);
      var atoken = localStorage.getItem('token');
      const {course,info, more} = this.state.myform;
      const baseURL = `https://grouptables.`+process.env.REACT_APP_BACKEND+`/grouptables/${thecourse}/${group}`  ; //process.env.REACT_APP_COURSE_BACKEND ;

      axios.get(baseURL,{headers: {
        'token': `${atoken}`
      }}
        ).then((response) => {
        console.log(response);
        this.setState({myform:response.data.response.sort((a,b)=>a.id-b.id)},()=>{console.log('checky data',this.state.myform)});
       
        toast("Groups fetched");
        // toast(response.data.token);
        // localStorage.setItem('token',response.data.token);
        // window.location.href = '/'
    
      }).catch((e)=>{
        console.log(e.response);
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast(e.response.data[a.toString()]+'.\n Are you sure students assigned to these part yet?');
            console.log(e.response.data[a.toString()][0]);
           }
        }
        
         
      });
    }

    handleSingleInput= (e)=>{
      const others = {...this.state.others};
      others[e.target.name] = e.target.value;
      
      this.setState({others},()=>{
        // console.log('bobo',this.state)
      });
      
    }
    
    manuallyUpdateForm = (index,column,value)=>{
      try{
      var myform = {...this.state.myform};
      myform[index][column] = value;
          console.log('hal',Object.values(myform));
        this.setState({myform:Object.values(myform)},()=>{
         
          //  this.props.sendvalues(this.state.myform)
         
          console.log('bobo',this.state.myform)});
        }catch(e){
          console.log(e.toString());
        }
    }

    myAutoFill = ()=>{
      var selections = this.state.others.selectedgroups;
      var splittedSelections = selections.split(',');
      
      splittedSelections.forEach((asplit)=>{
        var col = (this.state.others.selectedcolumn == '')?'A':this.state.others.selectedcolumn;
        if (asplit.includes('-')){
          var limits = asplit.split('-');
          var begin = parseInt(limits[0]);
          var end = parseInt(limits[1]);
          console.log(begin,end);
          for (var i = begin; i <= end; i++){
            
            this.manuallyUpdateForm(i-1,col
              ,this.state.others.valuetoassign);
          }

        }else{
          var loc = parseInt(asplit);
          this.manuallyUpdateForm(loc-1,col
            ,this.state.others.valuetoassign);
        }
        
      });

      toast('Values filled');
    }
   
    addColumns=(e)=>{
      e.preventDefault();
     
      var atoken = localStorage.getItem('token');
      const {course,info, more} = this.state.myform;
      const thecourse = decodeURI(window.location.href.split('/')[4]);
      const group = decodeURI(window.location.href.split('/')[5]);
      const startgroupno = this.state.myform.length+1;
      const rownos = this.state.others.morecolumn;
      const baseURL = `https://grouptables.`+process.env.REACT_APP_BACKEND+`/grouptables/more`  ; //process.env.REACT_APP_COURSE_BACKEND ;
      

      var val =  {...this.state.myform};
      var data = {group:group,groupno:startgroupno,rownos:rownos,course:thecourse};
      // console.log(baseURL,data);
      axios.post(baseURL,data,{headers: {
        'token': `${atoken}`
      }}
        ).then((response) => {
        console.log(response);
        this.setState({myform:response.data.data.sort((a,b)=>a.id-b.id)},()=>{console.log('checky data',this.state)});
       
        toast("Groups updated");
        // toast(response.data.token);
        // localStorage.setItem('token',response.data.token);
        // window.location.href = '/'
    
      }).catch((e)=>{
        console.log(e.response);
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast(e.response.data[a.toString()][0]);
            console.log(e.response.data[a.toString()][0]);
           }
        }
        
         
      });
    }

    updateGroups=(e)=>{
      e.preventDefault();
      console.log('haya');
      var atoken = localStorage.getItem('token');
      const {course,info, more} = this.state.myform;
      const baseURL = `https://grouptables.`+process.env.REACT_APP_BACKEND+`/grouptables`  ; //process.env.REACT_APP_COURSE_BACKEND ;

      var val =  {...this.state.myform};
      var data = {data:Object.values(val)};
      console.log(baseURL,data);
      axios.post(baseURL,data,{headers: {
        'token': `${atoken}`
      }}
        ).then((response) => {
        console.log(response);
        this.setState({myform:response.data.data},()=>{console.log('checky data',this.state)});
       
        toast("Groups updated");
        // toast(response.data.token);
        // localStorage.setItem('token',response.data.token);
        // window.location.href = '/'
    
      }).catch((e)=>{
        console.log(e.response);
        // window.location.href = '/'
        if(e.response.data['error']){
          toast(e.response.data['error']);
        }else{
          for (let a in e.response.data){
            toast(e.response.data[a.toString()][0]);
            console.log(e.response.data[a.toString()][0]);
           }
        }
        
         
      });
    }
    updateformhere =(e,index)=>{
     
        var myform = {...this.state.myform};
    
          myform[index][e.target.name] = e.target.value;
          console.log('hal',Object.values(myform));
        this.setState({myform:Object.values(myform)},()=>{
         
          //  this.props.sendvalues(this.state.myform)
         
          console.log('bobo',this.state.myform)});
   
    }
    componentDidMount(){
      this.getGroups();
     } 
    render() { 
      var myrows= [];
      // console.log('yeye',this.state.myform);
      Array.from(this.state.myform).forEach((value,index)=> {
        myrows.push( <GroupRow values={value} sendvalue ={(e)=>this.updateformhere(e,index)}/>)
        console.log('yaaye', value);
      });
      var columns = cleanRow((Array.from(this.state.myform)[0])??[]);


      var theads=[<td class=" sticky-col first-col" style={{"background-color" : "green"}}>  </td>];
      var selectOptions = [];
      console.log('hally see',Array.from(this.state.myform)[0],columns);
      for (const [key,value] of Object.entries(columns) ){
            selectOptions.push(
              <option value={key}>{key}</option>
            );
            theads.push(
                  <td style={{"background-color" : "white","text-align":"center"}}>
                  {key}
                  </td>
              );
  
        
      }
        return (
            <section >
    <section class="p-1">
    <div>
      <ToastContainer />
    </div>
      <div style={{
        // width:"3000px",
        padding:"15px"}} class=" shadow-lg ">
            <p class="pere text-secondary fs-3 text-warning mb-5 fw-bold"> {decodeURI(window.location.href.split('/')[4])} {decodeURI(window.location.href.split('/')[5])}</p>
          
            <a href={`/viewstudents/`+ decodeURI(window.location.href.split('/')[4])+ `/` +decodeURI(window.location.href.split('/')[5])}> View students</a>
            <br />

           
            <table>
              {/* <thead>
                <tr>
                  <td>Experiment:</td>
                  <td>Groups to assign to:</td>
                 
                  <td>Column or session/term/week:</td>
                  <td></td>
                </tr>
              </thead> */}
              <tbody>
                <tr>
                <td>Experiment:</td>
                  <td>
                  <input type="text" name="valuetoassign" id="valuetoassign" onChange={this.handleSingleInput} placeholder="e.g: Expriment 12 - (Date:12/2/23, Venue:LAB 8)" title="e.g: Expriment 12 - (Date:12/2/23, Venue:LAB 8)"  />
                  </td>
                  </tr><tr>
                  <td>Groups to assign to:</td>
                  <td>
                  <input type="text" name="selectedgroups" id="selectedgroups" onChange={this.handleSingleInput} placeholder="e.g: 1-3,4,7,12-15" title="e.g: 1-3,4,7,12-15" />
                  </td>
                  </tr><tr>
                  <td>Column or session/term/week:</td>
                  <td>
                  <select style={{width:"150px"}} name="selectedcolumn" id="selectedcolumn" onChange={this.handleSingleInput}>
            {selectOptions}
            </select>
                  </td>
                  </tr><tr>
                  <td> <button onClick={this.myAutoFill}> Fill Values</button></td>
                </tr>
              </tbody>
            </table>
            <br /> <br />
            

            <a href={`/viewattendance/`+ decodeURI(window.location.href.split('/')[4])+ `/` +decodeURI(window.location.href.split('/')[5])}>View Attendance Sheet</a>
            <br /> <br />
           {/* <label htmlFor="valuetoassign">Experiment:    </label>
            <input type="text" name="valuetoassign" id="valuetoassign" onChange={this.handleSingleInput} />
            <br />
            <label htmlFor="selectedgroups">Group to assign to:   </label>
            <input type="text" name="selectedgroups" id="selectedgroups" onChange={this.handleSingleInput} />
            
            <br />
            <label htmlFor="selectedcolumn">Column or session/term/week:    </label>
            <select style={{width:"150px"}} name="selectedcolumn" id="selectedcolumn" onChange={this.handleSingleInput}>
            {selectOptions}
            </select> */}
           
            <form class="row g-3 text-secondary">
              <div className="view">
                <div className="wrapper">

              <table  class="table" style={{
                // width:"3000px"
                }}>
                  <thead>
                    <tr>
                      {theads}
                    </tr>
                  </thead>
              {myrows}
              </table>
              <input type="number" name="morecolumn" id="morecolumn" onChange={this.handleSingleInput} placeholder="e.g: 2" title="e.g: 2" />

              <button onClick={this.addColumns}> Add more rows</button>                  
              </div>
              </div>
              <button onClick={this.updateGroups}>Update</button>
              
                  
              </form>
              </div>
              </section>
              </section>
        );
    }
}
 
export default ManageGroupsBody;