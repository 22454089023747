import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import MyImageButton from './my-imagebutton';

class StaffDashBody extends Component {
    state = {  } 


    componentDidMount(){
        var student = localStorage.getItem('qwrgggsdfg');
        if (student!=null){
          if (student=='yaya'){
             window.location.href = '/studentdashboard'
          }
        }
       } 
    
    render() { 
      
        return (
            <React.Fragment>
             <div>
      </div>
                <section class="showcase mt-5">
    <section class="p-3">
        <div class="container">
            <p class="per text-secondary fs-3 mb-5">Hello, <strong>{this.props.user.profile.name}</strong></p>
            <div class="row text-center g-4">
            <div class="col-md">
            <MyImageButton link='/staffprofile' image="/images/studentprofile.png" title='Profile' color='#238397'/>

                    {/* {MyImageButton("/staffprofile","/images/studentprofile.png",'Profile','#238397')} */}
                </div>
                <div class="col-md">
                    {/* <a href='/registerstudent'> <img class="stud img-fluid w-75" src="
                    images/regastudent.png" /></a> */}
                    <MyImageButton link='/registerstudent' image="/images/regastudent.png" title='Register' color='#B24B5C'/>
                </div>
                <div class="col-md">
                    {/* <a href='listCourses'><img class="staff img-fluid w-75" src="images/createacourse.png"/></a> */}
                    {/* {MyImageButton("listCourses","/images/createacourse.png","Courses",'#D69A3D')} */}
                    <MyImageButton link='listCourses' image="/images/createacourse.png" title='Courses' color='#D69A3D'/>

               </div>
                <div class="col-md">
                    {/* <a href='listgroups'><img class="staff img-fluid w-75" src="images/assigntogroup.png"/></a>  */}
                    {/* {MyImageButton("listgroups","/images/assigntogroup.png","Groups",'#6D5D59')} */}
                    <MyImageButton link='listgroups' image="/images/assigntogroup.png" title='Groups' color='#6D5D59'/>

                </div>
                <div class="col-md">
                    {/* <a href='/notice'><img class="staff img-fluid w-75" src="images/createanotice.png"/></a> */}
                    {/* {MyImageButton("/notice","/images/createanotice.png","Notice",'#DE6989')} */}
                    <MyImageButton link='/notice' image="/images/createanotice.png" title='Notice' color='#DE6989'/>

                </div>
                </div>
            </div>
        </section>
            </section>
              
            </React.Fragment>
        );
    }
}
 
export default StaffDashBody;


