import React, { Component } from 'react';
import CourseTile from './coursetile';
import { ToastContainer, toast } from 'react-toastify';
import Ads from './ads';


import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import AdsMedium from './ads_medium';
import AdsRect from './ads_rect_large';

class ListCoursesBody extends Component {
    state = { courses:[], loading:false } 

    componentDidMount()
    { 
     
      this.getMyCourse();
    
    }

    getMyCourse=()=>{
      this.setState({loading:true});
        var atoken = localStorage.getItem('token');
        var data = {};
    const baseURL = 'https://courses.'+process.env.REACT_APP_BACKEND+'/course/newallavailable' ;

    // console.log(baseURL,data,atoken);
    axios.post(baseURL,data,{headers: {
      'token': `${atoken}`
    }}
      ).then((response) => {
      console.log(response);
      // var newcourses = response.data.ownership ; will remove it works well with the line replaced
      var newcourses = response.data.courses ;
      // this.state = { courses:newcourses }  ;
      // console.log('check',newcourses);
      // console.log('checky',this.state.courses[0].email);
      this.setState({courses:newcourses,acourses:newcourses},()=>{console.log('checky again',this.state.courses)});
     
      toast("Courses fetched");
      this.setState({loading:false});
  
    }).catch((e)=>{
      console.log(e.response);
      // window.location.href = '/'
      if(e.response.data['error']){
        toast(e.response.data['error']);
      }else{
        for (let a in e.response.data){
          toast(e.response.data[a.toString()][0]);
          console.log(e.response.data[a.toString()][0]);
         }
      }
      
      this.setState({loading:false});
    });

    }
   
    filterCourses = (e)=>{

      var courses  = this.state.acourses;
      var filteredCourses= courses.filter((value)=>{
          return value.course.toLowerCase().includes(e.target.value.toLowerCase())||value.info.toLowerCase().includes(e.target.value.toLowerCase());
      });
      
      this.setState({courses:filteredCourses},()=>{console.log(this.state)});
      // console.log(filteredCourses,this.state);
    }

    buildCourse=()=>{
      
        var elements = [];
        for(var i =0; i<this.state.courses.length;i++){
          //console.log(this.state.courses[i].email);
            elements.push(
              <>
               
                <div style={{color:'green'}}>
                <a href={'/registerstudent/'+this.state.courses[i].course}> 
                 <h1>{this.state.courses[i].course}</h1>
                </a>
                  <h5>{this.state.courses[i].info}</h5>
                  </div >
                  


                </>

                // <CourseTile details={this.state.courses[i]} key={i.toString()} />               
            );
            
        }
        return elements;
    }

    ads= ()=>{
     var adsno = Math.round(this.state.courses.length/6);
     console.log(adsno)
     var arrayLikeRange = [...Array(adsno).keys()]
     var myads = arrayLikeRange.map(()=>{
      return <div>
      <a href='http://shop.varsityreghub.com'><img class="staff img-fluid w-75" src="images/chops_ads.png" /></a>
  <div style={{'height':'25px'}}></div>
  </div>
  ;
     }
     );
        
     return myads;
    }

    handleEmptyCourses = ()=>{
      if (this.state.loading){
        return <h3>Loading courses...</h3>
      }
      if(this.state.courses.length<1){
        return <h>No courses to display</h>}
      
    }
    render() { 
        //this.getMyCourse();
        return (
            <React.Fragment>
                <div>
       <ToastContainer />
     </div>
       <section class="showcase mt-5">
    <section class="p-3">
        <p class="per text-secondary fs-3 mx-4 mb-5">Welcome, <strong>{this.props.user?this.props.user.profile.name:'Guest'}</strong></p>
        <div class="container shadow-lg p-3 rounded bg-white px-5">
            <p class="pere text-secondary fs-3 text-warning mb-5">Course List</p>
            <h3>Select a course to register</h3>
            <hr />
            <form>
            <div class="row">
              <label class='col-12' for='search' >ENTER COURSE CODE TO SEARCH</label>
              <input class='col-12' type="text" name='search' onChange={this.filterCourses} id='search' placeholder='e.g ABC123' title='e.g ABC123'/>
              </div>
            </form>
            <br/>
            {/* <Ads> </Ads>
              <AdsMedium></AdsMedium>
              <AdsRect></AdsRect> */}
            <div className='mycontainer'>
              <div class='row'>
              <div class='col-10' >
            {this.buildCourse()}
            {this.handleEmptyCourses()}
              </div>
              <div class='col-2'></div>
              {/* <div class='col-4' >
            {this.ads()}
            </div>  */}
            </div>
            </div>
            </div>
    </section>
        </section>
                </React.Fragment>
        );
    }
}
 
export default ListCoursesBody;