import React, { Component } from 'react';
import {deptOptions} from '../services/departments';
import Select from 'react-select';

class GroupTile extends Component {
    state = {  } 
    handleinput= (e)=>{
        const myform = {...this.state.myform};
       
        if(e.target.name == 'department'){
          myform[e.target.name] = e.target.value.split(",").map((value, i)=>value.trim());
        }else{
          myform[e.target.name] = e.target.value;
        }
        this.setState({myform},()=>{
          // if(e.target.name !=='label'){
          //   this.props.sendvalues(this.state.myform,this.props.num)
          // }
          this.props.sendvalues(this.state.myform,this.props.num)
          console.log(this.state.myform)});

      }
      
      handleDeptChange = (selectedOption) => {
        const myform = {...this.state.myform};
        selectedOption = selectedOption.map((value)=>value.label)
        myform['department'] = selectedOption;
        this.setState({myform}, () =>{
        this.props.sendvalues(this.state.myform,this.props.num)
          console.log(`New form details:`, this.state.myform)
        }
        );
      };

    render() { 
        return (
            <>

<hr style={{backgroundColor:"red",height:'10px'}} />
            {/* <input type="hidden" class="form-control" id="inputname" onBlur={this.handleinput} name="viewno" value={this.props.obj.}/> */}
            <div class="col-md-6">
            <label for="inputname" class="form-label"> {this.props.num+1}. Group Name </label>
            <input type="coursename" class="form-control" id="inputname" onChange={this.handleinput} name="label" value={this.props.obj.label} placeholder='e.g. A' title='e.g. A' required/>
          </div>
          <div class="col-md-6">
            <label for="inputmatricno" class="form-label"> Number per group</label>
            <input type="coursetitle" class="form-control" id="inputmatricno" onChange={this.handleinput} pattern="[0-9]*"  name="numberpergroup" value={this.props.obj.numberpergroup} placeholder='e.g. 3' title='e.g. 3' required/>
          </div>
          <div class="col-md-10">
            {/* <label for="inputname" class="form-label">Department/Identifier</label>
            <input type="coursename" class="form-control" id="inputname" onChange={this.handleinput} name="department" value={this.props.obj.department} required /> */}
            <Select
        isMulti={true}
        isSearchable={true}
        value={this.props.obj.newdepartments}
        onChange={this.handleDeptChange}
        options={deptOptions}
      />
          </div>
          <div className="col-md-2">
          <button type="button" onClick={()=>this.props.adelete(this.props.obj.label,this.props.num)} style={{backgroundColor:"red"}}> Remove Group</button>

          </div>
          {/* <hr style={{backgroundColor:"red",height:'10px'}} /> */}
          </>
        );
    }
}
 
export default GroupTile;