import React, { Component } from 'react'

class AdsMedium extends Component{
    state = {  } 
    render() { 

        return ;
        // (
        //     // <div style={{width:'160px',height:'600px',backgroundColor:'blue'}} className='tileContainer'  >
        //                  <div style={{width:'160px',height:'600px',backgroundColor:'green'}} className='tileContainer'  >

        //     </div>
        // );
    }
}
 
export default AdsMedium;