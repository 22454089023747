
const cleanRow = (values) => {

   var exempt = ['id','group','created_at','updated_at','course','groupno']
   
        var cells={};
        for (const [key,value] of Object.entries(values) ){
            if(!exempt.includes(key) ){
                cells[key]=value;
            }
        
}
return cells;
}


export{
    cleanRow
}