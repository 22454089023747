import { Component } from "react";

class BackWithHeader extends Component{
    goBackToDash = ()=>{
        window.location.href = '/palmeeup'
    }
    render(){
        return <>
        <div>
        <i class="fa fa-arrow-circle-left backicon" onClick={this.goBackToDash} ></i>
        <h5 className="backtitle">{this.props.title}</h5>
        </div>
        </>
    }
}

export default BackWithHeader;