import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import {deptOptions} from '../services/departments';
import Select from 'react-select';

import axios from "axios";
import 'react-toastify/dist/ReactToastify.css'; 
import MypasswordInput from './mypasswordinput';
import MyTermsDialog from './mytermsdialog';



class StaffRegisterbody extends Component {
  state = { myform:{},loading:false } 
  setLoading = (val)=>{
    this.setState({loading:val});
  }
  handleSubmit = (e)=>{
    e.preventDefault();
    this.setLoading(true);
    if(!(this.state.myform.confirm)){
      this.setLoading(false);
      toast('Check "I confirm that the information provided is true"')
      return;
    }
    if(!(this.state.myform.terms)){
      this.setLoading(false);
      toast('Check "I agree to the terms and conditions"')
      return;
    }
   
    var atoken = localStorage.getItem('token');
    const baseURL = "https://auth."+process.env.REACT_APP_BACKEND+"/auth/register" ; //process.env.REACT_APP_COURSE_BACKEND ;

   //const {course,info, more} = this.state.myform;
    // const { groupingmap} = this.state
    var data = {...this.state.myform, usertype:'STAFF'};

    if(data.password!==data.passwordagain){
      this.setLoading(false);
      toast('Passwords don\'t match');
      return;
    }

    console.log("see me",baseURL,data);
    axios.post(baseURL,data,{headers: {
      'token': `${atoken}`
    }}
      ).then((response) => {
      // console.log(response);
      
      this.setLoading(false);

      toast("User registered");
      //  toast(response.data.token);
      localStorage.setItem('token',response.data.token);
      window.location.href = '/stafflogin'
  
    }).catch((e)=>{
      console.log(e);
      this.setLoading(false);
      
      // window.location.href = '/'
      if(e.response.data==null){
        toast(e.message);
      }else if(e.response.data['error']){
        toast(e.response.data['error']);
      }else{
        for (let a in e.response.data){
          // toast(e.message);
          toast(e.response.data[a.toString()][0]);
          console.log(e.response.data[a.toString()][0]);
         }
      }
      
       
    });

    // console.log(name,password);
  }
  handleinput= (e)=>{
    const myform = {...this.state.myform};
    myform[e.target.name] = e.target.value;
    this.setState({myform},()=>{
      // console.log(this.state.myform)
    });
    
  }

  handlecheckboxinput= (e)=>{
    const myform = {...this.state.myform};
    if(myform[e.target.name]){
      myform[e.target.name] = !myform[e.target.name];
    }else{
      myform[e.target.name] = true;
    }
    this.setState({myform},()=>{
      // console.log(this.state.myform)
    });
    
  }

  handleDeptChange = (selectedOption) => {
    const myform = {...this.state.myform};
    selectedOption = selectedOption.label;
    // selectedOption.map((value)=>value.label)
    myform['department'] = selectedOption;
    this.setState({myform}, () =>{
      // console.log(`New form details:`, this.state.myform)
    }
    );
  };

    render() { 
        return (
            <section class="showcase mt-5">
    <section class="p-5">
      <div class="container shadow-lg p-3 rounded bg-white px-5">
            <p class="pere text-secondary fs-3 text-warning mb-5 fw-bold">Staff Registration</p>
      <div>
       <ToastContainer />
     </div>
            <form class="row g-3 text-secondary" onSubmit={this.handleSubmit}>
                <div class="col-md-6">
                  <label for="inputname" class="form-label">Name</label>
                  <input type="name" class="form-control" id="inputname" onChange={this.handleinput} name="name"/>
                </div>
                <div class="col-md-6">
                  <label for="inputmatricno" class="form-label">Institutional Email</label>
                  <input type="text" class="form-control" id="inputinstitutionemail" onChange={this.handleinput} name="email"/>
                </div>
                <div class="col-md-6">
                  <label for="inputdepartment" class="form-label">Department</label>
                  {/* <input type="text" class="form-control" id="inputdepartment" onChange={this.handleinput} name="department"/> */}
                  <Select
        isMulti={false}
        isSearchable={true}
        value={{'label':this.state.myform.department,'value':this.state.myform.department}}
        onChange={this.handleDeptChange}
        options={deptOptions}
      />
                </div>
                <div class="col-md-6">
                    <label for="inputphonenumber" class="form-label">Phone number</label>
                    <input type="text" class="form-control" id="Phonenumber" onChange={this.handleinput} name="phonenumber"/>
                  </div>
                <div class="col-md-6">
                    <label for="inputPassword" class="form-label">Password</label>
                    <MypasswordInput className="form-control mb-3" onChange={this.handleinput} name='password' id="inputpassword" />

                    {/* <input type="password" class="form-control" id="inputPassword" onChange={this.handleinput} name="password"/> */}
                  </div>
                  <div class="col-md-6">
                    <label for="inputPassword4" class="form-label"> Confirm Password</label>
                    <MypasswordInput className="form-control mb-3" onChange={this.handleinput} name='passwordagain' id="inputPassword4" />

                    {/* <input type="password" class="form-control" id="inputPassword4" onChange={this.handleinput} name="passwordagain"/> */}
                  </div>
                  <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="confirm" onChange={this.handlecheckboxinput} id="gridCheck"/>
                    <label class="form-check-label" for="gridCheck">
                      I confirm that the information provided above is true. 
                    </label>
                    
                    
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="terms" onChange={this.handlecheckboxinput} id="gridCheckb"/>
                    <label class="form-check-label" for="gridCheckb">
                      I agree to the <MyTermsDialog linkTitle={'Terms and Conditions'}/>. 
                    </label>
                   
                  </div>
                </div>
                <div class="col-12">
                {/* <button type="submit" class="btn btn-info btn-lg text-white">Register Me</button> */}
                {(!this.state.loading)&&<button  class="btn btn-info btn-lg text-white">Register Me</button>}
                {(this.state.loading)&& <h5>Loading ...</h5>}
                </div>
              </form>
              </div>
              </section>
              </section>
        );
    }
}
 
export default StaffRegisterbody;