import React, { Component } from 'react';
import MyImageButton from './my-imagebutton';


class StudentDashBody extends Component {
    state = { name:"" } ;

    componentWillMount(){
        console.log('user prop:',this.props.user);
        var student = localStorage.getItem('qwrgggsdfg');
        
        if (student!=null){
          if (student=='nana'){
             window.location.href = '/staffdashboard'
          }
        }
       } 
   
    render() { 
      
        return (
            <React.Fragment>
                
               <section class="showcase mt-5">
    <section class="p-5">
        <p class="per text-secondary fs-3 mx-4 mb-5">Hello, <strong>{this.props.user.profile.name}</strong></p>
        <div class="container">
            <div class="row text-center g-4">
                <div class="col-md">
                <MyImageButton link = "/studentprofile" image="/images/studentprofile.png" title= 'Profile' color='#238397'/>
                    {/* <a href='/studentprofile'> <img class="stud img-fluid w-75" src="images/studentprofile.png"/></a> */}
                </div>
                <div class="col-md">
                <MyImageButton link = "/registerstudent" image="/images/regastudent.png" title= 'Register' color='#B24B5C'/>

                    {/* <a href='/registerstudent'> <img class="stud img-fluid w-75" src="images/regastudent.png" /></a> */}
                </div>
                
                <div class="col-md" height='323px' width='119px'>

                    <a href='http://shop.varsityreghub.com'><img class="staff img-fluid w-75" src="images/cakes_ads.png" /></a>
                </div>
                <div class="col-md">
                <MyImageButton link = "/viewgroup" image="/images/viewgroups.png" title= 'Lab Groups' color='#D69A3D'/>

                    {/* <a href='/viewgroup'><img class="staff img-fluid w-75" src="images/viewgroups.png"/></a> */}
                </div>
                <div class="col-md">
                <MyImageButton link = "/palmeeup" image="/images/connectwithgroup.png" title= 'Connect with group members' color='#7A8278'/>

                    {/* <a href='/seepalmeeup'><img class="staff img-fluid w-75" src="images/connectwithgroup.png"/></a> */}
                </div>
            </div>
        </div>
        </section>
            </section>
           
                </React.Fragment>
        );
    }
}
 
export default StudentDashBody;