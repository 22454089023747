import React, { Component } from 'react'

class Ads extends Component{
    state = {  } 
    render() { 

        return ;
        // (
        //     <div style={{width:'728px',height:'90px',backgroundColor:'blue'}} className='tileContainer'  >
             
        //     </div>
        // );
    }
}
 
export default Ads;