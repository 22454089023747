
import React, { Component } from 'react'

class MyHomeImageButton extends Component{
    state = {  } 
    render() { 

        return <a href={this.props.link}>
        <div className="image-container">
            <img class="stud fluid w-50" src={this.props.image} />
            <button className="homebtn" style={{backgroundColor:this.props.color}}>

                {this.props.title}
            </button>
        </div>

    </a>;
    }
}

export default MyHomeImageButton;