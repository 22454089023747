import React from 'react';
import axios from "axios";


class BannerImage extends React.Component {

    state = {
        imageSrc: null,
        progress: 0,
        ads:[]
    };

     
    getAds= ()=>{
        //get course details
        const baseURL = `https://ads.varsityreghub.com/getads/banner` ;
        
        axios.get(baseURL,{headers: {
        //   'token': `${this.atoken}`
        }} ).then((response) => {
        var ads = response.data.ads;
          this.setState({ads:ads},()=>{
           console.log('see ads updated',this.state);
           if(this.state.ads.length>0){
            this.fetchImage("https://ads.varsityreghub.com/adimage/"+this.state.ads[0].image);
           }
          
         });
           });
       
       }

       fetchImage = async (url) => {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Failed to fetch image');
          }
          const blob = await response.blob();
          this.setState({ imageSrc: URL.createObjectURL(blob) });
        } catch (error) {
          console.error(error);
        }
      };

    componentDidMount() {
        this.getAds();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        clearInterval(this.progressInterval);
        if (this.state.imageSrc) {
            URL.revokeObjectURL(this.state.imageSrc);
          }
    }

    render() {
        
        return (
           
            (this.state.imageSrc!=null)? 
            <div >
               <a href={this.state.ads[0].link}> <img src={this.state.imageSrc} width="100%"  maxHeight="40vh" /> </a>
                </div>:
                <div></div>
        );
    }
}

export default BannerImage;
