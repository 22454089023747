import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {deptOptions} from '../services/departments';
import Select from 'react-select';

import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

class StudentProfileBody extends Component {
  state = {  myform:{} } 

  componentDidMount(){
    this.getAProfile();
   } 

   getAProfile=()=>{
    const queryParams = new URLSearchParams(window.location.search);


      var atoken = localStorage.getItem('token');
    var data = {"token":atoken};
const baseURL = "https://auth."+process.env.REACT_APP_BACKEND+"/auth/studentprofile"; //process.env.REACT_APP_COURSE_BACKEND ;

console.log(baseURL,data);
axios.get(baseURL,{headers: {
  'token': `${atoken}`
}}
  ).then((response) => {
  var {profile} = response.data ;
  // // this.state = { courses:newcourses }  ;
  // // console.log('check',newcourses);
  // // console.log('checky',this.state.courses[0].email);
  this.setState({myform:profile,},()=>{
    // this.getallAdmins();
    console.log('checky again',this.state)});
  

}).catch((e)=>{
  console.log(e.response);
  // window.location.href = '/'
  if(e.response.data['error']){
    toast(e.response.data['error']);
  }else{
    for (let a in e.response.data){
      toast(e.response.data[a.toString()][0]);
      console.log(e.response.data[a.toString()][0]);
     }
  }

});

}

  handleSubmit = (e)=>{
    e.preventDefault();
    var atoken = localStorage.getItem('token');
    const baseURL = "https://auth."+process.env.REACT_APP_BACKEND+"/auth/studentprofile"; //process.env.REACT_APP_COURSE_BACKEND ;
    
   //const {course,info, more} = this.state.myform;
    // const { groupingmap} = this.state
    var data = {...this.state.myform};

    console.log("see me",baseURL,data);
    axios.post(baseURL,data,{headers: {
      'token': `${atoken}`
    }}
      ).then((response) => {
      console.log(response);
      
      window.location.href = '/studentdashboard'
  
    }).catch((e)=>{
      console.log(e.response);
      // window.location.href = '/'
      if(e.response.data['error']){
        toast(e.response.data['error']);
      }else{
        for (let a in e.response.data){
          toast(e.response.data[a.toString()][0]);
          console.log(e.response.data[a.toString()][0]);
         }
      }
      
       
    });

    // console.log(name,password);
  }
  handleinput= (e)=>{
    const myform = {...this.state.myform};
    myform[e.target.name] = e.target.value;
    this.setState({myform},()=>{console.log(this.state.myform)});
    
  }
  handleDeptChange = (selectedOption) => {
    const myform = {...this.state.myform};
    selectedOption = selectedOption.label;
    // selectedOption.map((value)=>value.label)
    myform['department'] = selectedOption;
    this.setState({myform}, () =>{
      console.log(`New form details:`, this.state.myform)
    }
    );
  };

  render() { 
    
      return (
          <React.Fragment>
           <div>
     
      <ToastContainer />
    </div>
              <section className="showcase mt-2">
  <section className="p-3">
      <div className="container shadow-lg rounded bg-white px-3">
          <p className="pere text-secondary fs-3 text-warning pt-4 px-3 fw-bold">Student Profile</p>
          <form onSubmit={this.handleSubmit} className="row g-3 text-secondary">
              <div className="col-md-4 p-4">
              <label for="name" className="form-label fw-bold" >Name</label>
                <input type="text" className="form-control mb-3" onChange={this.handleinput} name='name' id="name" value={this.state.myform.name} />
                <label for="email" className="form-label fw-bold" >Email</label>
                <input type="text" className="form-control mb-3" onChange={this.handleinput} name='email' id="email" value={this.state.myform.email} disabled/>
               
                <label for="department" className="form-label fw-bold" >Department</label>
                {/* <input type="text" className="form-control mb-3" onChange={this.handleinput} name='department' id="department" value={this.state.myform.department}/> */}
                
                <Select
        isMulti={false}
        isSearchable={true}
        value={{'label':this.state.myform.department,'value':this.state.myform.department}}
        onChange={this.handleDeptChange}
        options={deptOptions}
      />
      <br/>

                <label for="phonenumber" className="form-label fw-bold">Phone Number</label>
                <input type="phonenumber" className="form-control mb-3" onChange={this.handleinput} name='phonenumber' id="phone" value={this.state.myform.phonenumber} />
                <label for="level" className="form-label fw-bold">Level</label>

                <input type="level" className="form-control mb-3" onChange={this.handleinput} name='level' id="level" value={this.state.myform.level} />
                <label for="matricnumber" className="form-label fw-bold">Matric Number</label>
                <input type="matricnumber" className="form-control mb-3" onChange={this.handleinput} name='matricnumber' id="phone" value={this.state.myform.matricnumber} />
                {/* <div className="form-check">
                  <input className="form-check-input" type="checkbox" onChange={this.handleinput} name="rememberme" id="gridCheck"/>
                  <label className="form-check-label mb-2" for="gridCheck">
                    Remember Me
                  </label>
                </div> */}
                
                <div className="course mt-5">
                    <button type='submit'  className="btn btn-info btn-lg px-5 text-white fw-bold">Update</button>
                </div>
              </div>
              <div className="col-md-1">
              </div>
              <div className="col-md-4">
              <img className="recruit img-fluid w-75 d-none d-sm-block mb-2" src="images/casual-life-3d-recruiter-woman-having-online-interview.png"/>
              </div>
              <div class="col-md-3" height='100px' width='50px'>
                    <a href='http://shop.varsityreghub.com'><img class="staff img-fluid w-75 mb-3" src="images/chops_ads.png" /></a>
                </div>
                
            </form>
            </div>
            </section>
            </section>
            
          </React.Fragment>
      );
  }
}
 
export default StudentProfileBody;